import { BlogCardOptions } from "@/types";
import { Link } from "gatsby";
import React, { FC } from "react";

export const BlogCard: FC<{ props: BlogCardOptions }> = ({
  props: { authorName, authorPic, blogTitleImage, createdAt, slugUrl, title },
}) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-blog-post bg-fffbf5">
        <div className="post-image">
          <Link to={"/" + slugUrl}>
            <img className="blog-image" src={blogTitleImage.file.url} alt="..." />
          </Link>
        </div>

        <div className="post-content">
          <ul className="post-meta d-flex justify-content-between align-items-center">
            <li>
              <div className="post-author d-flex align-items-center">
                <img src={authorPic.file.url} className="rounded-circle" alt="..." />
                <span>{authorName}</span>
              </div>
            </li>
            <li>
              <i className="flaticon-calendar"></i>{" "}
              <strong>{new Date(createdAt).toString().split(" ")[1] + " "}</strong>
              <span>{new Date(createdAt).toString().split(" ")[2] + " "}</span>
              <span>{new Date(createdAt).toString().split(" ")[3]}</span>
            </li>
          </ul>
          <h3>
            <Link className="cut-text1" to={"/" + slugUrl} title={title}>
              {title}
            </Link>
          </h3>
        </div>
      </div>
    </div>
  );
};
