import { BlogCardOptions, SEOType } from "@/types";
import React, { FC } from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import { BlogCard } from "../Shared/Design/BlogCard";
import BreadCrumb from "../Shared/extra/BreadCrumb";

interface BlogListPageProps {
  props: {
    seo: SEOType;
    blogs: BlogCardOptions[];
    footerContent?: any;
  };
}

const BlogListPage: FC<BlogListPageProps> = ({ props: { seo, blogs, footerContent } }) => {
  return (
    <Layout footerContent={footerContent}>
      <BreadCrumb
        pageTitle="Blogs"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/blog", pathName: "Blogs" },
        ]}
      />

      <SEO contentfulSeo={seo} />

      <div className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            {blogs.map(
              ({ id, authorName, authorPic, blogTitleImage, createdAt, slugUrl, title }) => {
                return (
                  <BlogCard
                    props={{ id, authorName, authorPic, blogTitleImage, createdAt, slugUrl, title }}
                    key={id}
                  />
                );
              },
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogListPage;
